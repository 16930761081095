import React, { useEffect, useState } from 'react';
import {  Link, useNavigate  } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';


const Create = () => {

    const [isLoading, setIsLoading]   = useState(true);
	const [appInfo, setAppInfo]       = useState(null);
	const navigate                    = useNavigate();



	const [ville, setVille]           = useState('');
	const [quartier, setQuartier]     = useState('');
	const [complement, setComplement] = useState('');
	const [prix, setPrix]             = useState('');

	const dateCreated                 = new Date().toLocaleString();
	const country                     = "Cameroun";
    

    const [formData, setFormData] = useState({
        ville: "",
        quartier: "",
        complement: "",
        prix: "",
        country: "Cameroun",
        dateCreated: new Date().toLocaleString()
    });

    const [photo, setPhoto]       = useState(null);
    const [imageURL, setImageURL] = useState(""); // State pour l'URL de l'image
    const [message, setMessage]   = useState("");
    const [loading, setLoading]   = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    
    const handleFileChange = (e) => {
        setPhoto(e.target.files[0]);
    };


    const handleSubmit = (e) => {

        e.preventDefault(); // Empêche le rechargement de la page lors de la soumission
        setLoading(true); // Active le chargement
      
        // Vérification si un fichier photo est sélectionné et qu'il est au format .png ou .jpg
        if (!photo || (!photo.name.toLowerCase().endsWith('.png') && !photo.name.toLowerCase().endsWith('.jpg'))) {
          setLoading(false); // Désactive le chargement
          setMessage('Le fichier doit être au format .png ou .jpg'); // Affiche un message d'erreur
          return;
        }
      
        setMessage(''); // Réinitialise le message précédent
      
        // Convertir l'image en base64 pour l'envoyer à Android
        const reader = new FileReader();
        reader.readAsDataURL(photo);
        reader.onload = () => {
          const imageBase64 = reader.result.split(',')[1]; // Récupère uniquement la partie base64
      
          // Vérification que l'objet Android est disponible et que la fonction est définie
          if (typeof window.Android !== 'undefined' && typeof window.Android.NewDocWithFile === 'function') {
            const collectionPath = 'chambres'; // Chemin de la collection Firestore
      
            // Préparer les données à envoyer
            const dataToSend = {
              ...formData,
              imageBase64, // Ajouter l'image encodée en base64
            };
      
            // Appeler la fonction Android pour insérer les données
            window.Android.NewDocWithFile(collectionPath, JSON.stringify(dataToSend), (success) => {
              if (success) {
                // Réinitialiser les champs du formulaire après succès
                setVille('');
                setQuartier('');
                setComplement('');
                setPrix('');
                setPhoto(null);
                setMessage('Insertion réussie !');
              } else {
                setMessage('Échec de l\'insertion. Veuillez réessayer.');
              }
              setLoading(false); // Désactive le chargement
            });
          } else {
            setMessage('La fonction Android n\'est pas disponible.');
            setLoading(false); // Désactive le chargement
          }
        };
      
        reader.onerror = () => {
          setMessage('Erreur lors de la lecture du fichier image.');
          setLoading(false); // Désactive le chargement
        };
    };
    

    /****   Camera  ****/
	const openPicker = () => {
        if (typeof window.Android !== 'undefined' && typeof window.Android.openImagePicker === 'function') {
            window.Android.openImagePicker(); // Appel de la fonction Kotlin pour ouvrir la caméra
        } else {
            setMessage("L'interface Android n'est pas disponible");
        }
    };


    // Cette fonction est appelée lorsqu'une nouvelle image est sélectionnée et que l'URL est injectée dans l'état
    window.onImageSelected = (base64Image) => {
        setImageURL(base64Image); // Mettre à jour l'URL de l'image avec la valeur en base64
    };

     const handleImageLoad = () => {
        // Une fois que l'image est chargée, on met à jour l'état de chargement
        setIsLoading(false);
    };

    
	useEffect(() => {
            // Récupérer les données depuis localStorage dès que le composant est monté
            const data = localStorage.getItem('AppInfo');
            setTimeout(() => {
              if (data) {
                // Parsez les données JSON récupérées
                const parsedData = JSON.parse(data);
                
                // Vérifiez la valeur de 'logged' et mettez à jour l'état
                if (parsedData.logged === true) {
                  setAppInfo(parsedData); // Mettre à jour les informations de l'app
                  setIsLoading(false);  // Pas de données, terminer le chargement
                }else {
                    setAppInfo(parsedData);
                    setIsLoading(false);
                    //navigate('/auth');
                }
              } else {
                setIsLoading(false);  // Pas de données dans localStorage
              }
            }, 1000);
    }, [navigate]);


	// Pendant le chargement, vous pouvez afficher un indicateur visuel de chargement ou simplement retourner null
	if (isLoading) {
      return <div><><div className="loader fs-4 fw-normal text-dark p-5">
		  <Spinner animation="border" variant="danger" role="status">
			  <span className="visually-hidden text-light">Loading...</span>
		  </Spinner>
      </div></> </div>;
    }

    return (
        <div className='App'>
     
		    {appInfo ? (
				<>
                    <nav className="fixed-top py-2">
						<div className="container d-flex align-items-center justify-content-between">
							{/* Logo et Titre */}
							<div className="d-flex align-items-center">
                                <Link onClick={() => window.history.back()} className="fs-5 fw-bolder text-white me-5" style={{ textDecoration: 'none'}} aria-label="User Account">
                                        <i className="fa-solid fs-3 fa-arrow-left"></i> <span className='ms-2'>Ajouter</span> 
                                </Link>
							</div>
						</div>
					</nav>
					<br /> <br /> <br /> 
					<div className='p-2'>
							<div className="box-features p-4 mt-4 bg-white">
                               
                                <form onSubmit={handleSubmit}>
                           
                                    <div className='mb-3 mt-4'>
                                        <div className='mt-3'>
                                            <h2 className="float-start fw-bolder" style={{ color: '#C84C47', fontFamily: 'tahoma' }}>
                                                <i class="fa-solid fa-bed me-2"></i> Nouvelle chambre  
                                            </h2>
                                        </div>
                                    </div>
                                    <br /> <br />

                                    <div className="mb-4 mt-3 float-start" style={{ width: '100%' }}>
                                        <Link
                                            onClick={openPicker} 
                                            className='float-start' 
                                            style={{ textDecoration: 'none' }}
                                        >
                                            <img 
                                                id="imageView"
                                                src={imageURL ? `data:image/jpeg;base64,${imageURL}` : `${process.env.PUBLIC_URL}/logo512.jpg` } 
                                                alt="default"
                                                style={{ width: '30%', borderRadius: '10px' }} 
                                                className='bg-light'
                                                onLoad={handleImageLoad} // Appeler handleImageLoad une fois l'image chargée
                                            />
                                            <span className='text-secondary ms-1 mt-2' style={{ textDecoration: 'none' }}>
                                                <i className="fa-solid fa-camera"></i>
                                            </span>							
                                        </Link>
                                    </div>
                                    
                                    <div className="mb-3">
                                        <label className="float-start fw-bold text-dark">Ville</label>
                                        <select
                                            className="form-control"
                                            name="ville"
                                            value={formData.ville}
                                            onChange={handleChange}
                                            required
                                        >
                                            <option value="">--Veuillez choisir une option--</option>
                                            <option value="Douala">Douala</option>
                                            <option value="Yaoundé">Yaoundé</option>
                                        </select>
                                    </div>
                                    <div className="mb-3">
                                        <label className="float-start fw-bold text-dark">Quartier</label>
                                        <input
                                            type="text"
                                            name="quartier"
                                            value={formData.quartier}
                                            onChange={handleChange}
                                            className="form-control"
                                            placeholder="Ex: Face petit terrain"
                                            required
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="float-start fw-bold text-dark">Complément</label>
                                        <input
                                            type="text"
                                            name="complement"
                                            value={formData.complement}
                                            onChange={handleChange}
                                            className="form-control"
                                            placeholder="Ex: Face petit terrain"
                                            required
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label className="float-start fw-bold text-dark">Prix la nuit</label>
                                        <input
                                            type="number"
                                            name="prix"
                                            value={formData.prix}
                                            onChange={handleChange}
                                            className="form-control"
                                            placeholder="Minimum 500 FCFA"
                                            required
                                        />
                                    </div>
                                    
                                    <button type="submit" className="go mt-3" >
                                        {loading ? (
                                            <div className="text-light">
                                                <Spinner as="span" aria-hidden="true" animation="border" size="sm" variant="light" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </Spinner>
                                                <span className="ms-1">Chargement...</span>
                                            </div>
                                        ) : (
                                            'Ajouter'  
                                        )}  <i className="fa-solid fa-add ms1"></i>
                                    </button>
                                    <div className="text-success fw-bold mt-1" style={{ height: '20px'}}>
                                       {message && {message} }
                                    </div>
                                </form>
							</div>
					</div>
			    </>
            ) : (
			    <>
				    <div className='App-Container'>
						<div className="text-center">
							<h2 className="text-danger">Erreur: Informations de l'application non disponibles</h2>
						</div>
			        </div>
				</>
            )}	   
        </div>     
    )
};

export default Create;
