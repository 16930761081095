import React, { useEffect, useState } from 'react';
import {  Link, useNavigate  } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';


const Welcome = () => {

    const [isLoading, setIsLoading] = useState(true);
	const [appInfo, setAppInfo]     = useState(null);
	const [documents, setDocuments] = useState([]);
	const navigate = useNavigate();


	const logout = () => {
		setIsLoading(true);
		setTimeout(() => {
			if (window.Android && typeof window.Android.o00011 === 'function') {
				window.Android.o00011(() => {
				  localStorage.clear();
				});
			} else {
				navigate('/un');
			}
		}, 1000);
	};

	const handleImageLoad = () => {
		// Une fois que l'image est chargée, vous pouvez vérifier si toutes les ressources sont prêtes
		setIsLoading(false);
	};


	useEffect(() => {
		// Récupérer les données depuis localStorage dès que le composant est monté
		const data = localStorage.getItem('AppInfo');
		setTimeout(() => {
		  if (data) {
			// Parsez les données JSON récupérées
			const parsedData = JSON.parse(data);
			
			// Vérifiez la valeur de 'logged' et mettez à jour l'état
			if (parsedData.logged === true) {
			  if (window.Android && typeof window.Android.getDataFromColl === 'function') {
				const collectionPath = "chambres";
				window.Android.getDataFromColl(collectionPath)((fetchedDocuments) => {
				  // Une fois les documents récupérés, mettez à jour l'état
				  setDocuments(fetchedDocuments);
				  setIsLoading(false);  // Mettre à jour le statut de chargement
				});
			  } else {
				navigate('/un');  // Rediriger si l'API Android n'est pas disponible
			  }
			} else {
			  setAppInfo(parsedData); // Mettre à jour les informations de l'app
			  setIsLoading(false);  // Pas de données, terminer le chargement
			}
		  } else {
			setIsLoading(false);  // Pas de données dans localStorage
		  }
		}, 1000);
	}, [navigate]);

	
	// Pendant le chargement, vous pouvez afficher un indicateur visuel de chargement ou simplement retourner null
	if (isLoading) {
      return <div><><div className="loader fs-4 fw-normal text-dark p-5">
		  <Spinner animation="border" variant="light" role="status">
			  <span className="visually-hidden text-light">Loading...</span>
		  </Spinner>
      </div></> </div>;
    }

    return (
        <div className='App'>
     
		    {appInfo ? (
				<>
					<nav className="fixed-top py-2">
						<div className="container d-flex align-items-center justify-content-between">
							{/* Logo et Titre */}
							<div className="d-flex align-items-center">
								<img
									src={`${process.env.PUBLIC_URL}/logo512.jpg`}
									className="rounded-circle bg-light"
									style={{ width: '40px', height: '40px' }}
									alt="Logo"
								/>
								<h4 className="fw-bold ms-3 fs-5 mb-0 text-white">{appInfo.name}</h4>
							</div>

							{/* Bouton de déconnexion */}
							<button
								onClick={logout}
								className="btn btn-light btn-sm d-flex align-items-center justify-content-center"
								style={{ width: '25px', height: '25px', borderRadius: '50%' }}
							>
								<i className="fa-solid fa-power-off text-danger"></i>
							</button>
						</div>
					</nav>

					<br /> <br /> <br /> 
					<div className='p-2'>

					    {isLoading ? (
							<div>Chargement...</div>
						) : (
							<div className="box-features mt-4 bg-white">
							{documents.length > 0 ? (
								documents.map((doc, index) => (
								<div key={index} className="item">
									<div style={{ height: '250px' }}>
									<img 
										style={{ width: '100%', height: '100%' }} 
										className="img-room" 
										onLoad={handleImageLoad} 
										alt="item" 
										src={doc.imageUrl || 'https://img.leboncoin.fr/api/v1/lbcpb1/images/default.jpg'}
									/>
									</div>
									<div className="col-md-12 div-details-welcome">
									<div style={{ height: '50px' }}>
										<h5 className="details1">{doc.name || 'Nom de l\'article'}</h5>
									</div>
									<h6 className="text-dark">
										<i className="fa-solid fa-location me-1"></i>
										{doc.location || 'Lieu de l\'article'}
									</h6>
									<h6 className="mt-1" style={{ color: '#545454' }}>
										<i className="fa-solid fa-money-bills me-1"></i> {doc.price || 'Prix'} FCFA / nuit
									</h6>
									<div>
										<Link className="btn-start fs-6 text-center">
										Ouvrir <i className="fa-solid fa-plus me-2"></i>
										</Link>
									</div>
									</div>
								</div>
								))
							) : (
								<div>Aucune chambre à afficher</div>
							)}
							</div>
						)}
					</div>
					<Link to='/create'><i style={{background: '#fff', color: '#783031'}} className="fa fa-add floating p-4 fs-3 me-1" ></i></Link>
					
			    </>
            ) : (
			    <>
				    <div className='App-Container'>
						<div className="text-center">
							<h2 className="text-danger">Erreur: Informations de l'application non disponibles</h2>
						</div>
			        </div>
				</>
            )}	   
        </div>     
    )
};

export default Welcome;
